import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Subscription } from 'rxjs';
import { ProgramBeneficiarySelectionList } from 'src/app/models/admin/program/program/program';
import { ProgramBeneficiaryList } from 'src/app/models/beneficiary/beneficiaryList';

@Component({
  selector: 'app-program-beneficiary-selection-list',
  templateUrl: './program-beneficiary-selection-list.component.html',
  styleUrls: ['./program-beneficiary-selection-list.component.sass']
})
export class ProgramBeneficiarySelectionListComponent  implements OnInit, OnDestroy{

  @Input() embedded: boolean = false;
  @Input() programId: number;
  @Input() programOrganizationId: number;
  @Input() selectedElements: ProgramBeneficiarySelectionList[] = [];
  @Input() showTitle: boolean = true;
  @Output() selectedBeneficiaries = new EventEmitter<ProgramBeneficiarySelectionList[]>();

  public form: FormGroup = new FormGroup({});
  public search: string = "";

  programBeneficiaries: ProgramBeneficiarySelectionList[] = [];

  filteredProgramBeneficiaries: ProgramBeneficiarySelectionList[] = [];
  searchListener: Subscription;

  constructor(public dialogRef: MatDialogRef<ProgramBeneficiarySelectionListComponent>, @Inject(MAT_DIALOG_DATA) public data: ProgramBeneficiarySelectionListInput, private myHttp: MyHttpHandler, public readonly _translateService: TranslateService) {
    if(!this.embedded && data){
      this.programId = data.programId;
      this.programOrganizationId = data.programOrganizationId;
      this.selectedElements = data.selectedElements;
      this.showTitle = true;
    }
    if(this.selectedElements == undefined) this.selectedElements = [];
    this.createFormGroups();

    this.form.controls['tagsIds'].valueChanges.subscribe((selectedElements) => {
      this.selectedElements = [];
      selectedElements.forEach(selectedElement => {
        if (!this.selectedElements.includes(selectedElement)) {
          this.selectedElements.push(selectedElement);
        }
      });
      this.selectedBeneficiaries.emit(this.selectedElements);
    })
  }
  ngOnDestroy(): void {
    if(this.searchListener) this.searchListener.unsubscribe();
  }

  ngOnInit(): void {
    this.getProgramBeneficiaryList();
    this.setupSearchListener();
  }

  private createFormGroups() : void{
    this.form = new FormGroup({
      search: new FormControl(''),
      'tagsIds': new FormControl({ value: this.selectedElements, disabled: false }),
    });
  }


  getProgramBeneficiaryList(): void{
    if(this.programId && this.programId > 0 && this.programOrganizationId && this.programOrganizationId > 0){
      let url = "/v1/program/program-beneficiary/" + this.programId;
      this.myHttp.get<ProgramBeneficiaryList>(url).subscribe(response =>{
        if(response && response.body) {
          let programBeneficiaryList = response.body;
          if(programBeneficiaryList && programBeneficiaryList.programOrganizationBeneficiaries.length > 0){
            for(let programOrganizationBeneficiary of programBeneficiaryList.programOrganizationBeneficiaries){
              if(programOrganizationBeneficiary?.programOrganization?.id == this.programOrganizationId){
                this.programBeneficiaries = programOrganizationBeneficiary.programBeneficiaries;
                this.filterProgramBeneficiaries(this.form.get('search')?.value || '');
                break;
              }
            }
          }
        }
      });
    }
  }

  handleSelection(event: MatCheckboxChange, progBeneficiary: ProgramBeneficiarySelectionList){
    if(event.checked && progBeneficiary){
      this.selectedElements.push(progBeneficiary);
    } else{
      let index = this.getElementIndexInSelectionList(progBeneficiary);
      if(index != undefined && index >= 0) this.selectedElements.splice(index, 1);
    }
    this.selectedBeneficiaries.emit(this.selectedElements);
  }

  getElementIndexInSelectionList(progBeneficiary: ProgramBeneficiarySelectionList){
    let index : number;
    if(progBeneficiary && this.selectedElements){
      let countIndex = 0;
      for(let element of this.selectedElements){
        if(index) break;
        if(element.beneficiary.id == progBeneficiary.beneficiary.id) index = countIndex;
        countIndex++;
      }
    }
    return index;
  }

  isSelected(progBeneficiary: ProgramBeneficiarySelectionList){
    let isSelected = false;
    if(progBeneficiary){
      let index = this.getElementIndexInSelectionList(progBeneficiary);
      isSelected = index != undefined && index >= 0;
    }
    return isSelected;
  }

  submitSelection(){
    if(!this.embedded) this.dialogRef?.close(this.selectedElements);
  }

  public getCurrentSelection(): ProgramBeneficiarySelectionList[]{
    return this.selectedElements;
  }

  private setupSearchListener(): void {
    this.searchListener = this.form.get('search')?.valueChanges.subscribe(searchTerm => {
      this.filterProgramBeneficiaries(searchTerm);
    });
  }

  filterProgramBeneficiaries(searchTerm: string = ''): void {
    searchTerm = searchTerm.toLowerCase();
    this.filteredProgramBeneficiaries = this.programBeneficiaries.filter(progBeneficiary => {
      return progBeneficiary.beneficiary.name.toLowerCase().includes(searchTerm);
    });
  }

}


export class ProgramBeneficiarySelectionListInput{
  constructor(
    public programId: number,
    public programOrganizationId: number,
    public selectedElements: ProgramBeneficiarySelectionList[]
  ) { }
}
