<div [formGroup]="form">
  <div *ngIf="showTitle">
    <h5>{{ 'program.buttons.select-beneficiary' | translate }}</h5>
  </div>
  <div class="list-container">
    <mat-form-field class="full-width">
      <input matInput 
             placeholder="{{ 'dashboard.titles.search' | translate }}" 
             formControlName="search">
    </mat-form-field>
    <div *ngFor="let progBenef of filteredProgramBeneficiaries" class="benef-selection-container">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="handleSelection($event, progBenef)" [checked]="isSelected(progBenef)">
        {{progBenef?.beneficiary?.name}}
      </mat-checkbox>
    </div>
  </div>
  <!-- <app-multiple-attendees-selection *ngIf="this.programBeneficiaries.length > 0"
    [placeHolder]="'dashboard.fields.tags' | translate"
    formControlName="tagsIds"
    [beneficiaries]="programBeneficiaries"
    [attendees]="this.selectedElements"
  ></app-multiple-attendees-selection> -->
  <button *ngIf="!embedded" type="button" class="btn btn-submit" (click)="submitSelection()">
    <span style="white-space: nowrap;">{{'global.save' | translate}}</span>
  </button>
</div>
